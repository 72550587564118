import React, { useEffect } from "react";

import { graphql } from "gatsby";
import MainLayout from "../layouts/MainLayout";

// import Custom Components
import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import ProfileTwo from "../components/features/profile/profile-two";
import IconBox from "../components/features/icon-box";
import SEO from "../components/common/SEO";
import { countTo } from "../utils";
// import data from '../../../mock_data/data.json';

function AboutTwo({ data }) {
  useEffect(() => {
    countTo();
  });

  return (
    <MainLayout>
      <div className="main">
        <SEO title="Jimenez Store Mr. Discount - About Page" />
        <h1 className="d-none">Jimenez Store Mr. Discount - About Page</h1>

        <PageHeader title="About us" />
        <Breadcrumb title="About Us"  />

        <div >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="about-text text-center mt-3">
                  <h2 className="title text-center mb-2">Who We Are</h2>
                  <p>
                    We are a family-owned company located in Indianapolis, IN,
                    with nearly a decade of experience in the home renovation
                    business. We have proudly served thousands of families
                    online and throughout the community with all their home
                    improvement needs. We are dedicated to hard work, quality
                    products, and customer service.{" "}
                  </p>

                  <img
                    src={`/assets/images/about/about-2/img-1.jpg`}
                    alt="temp"
                    className="mx-auto mb-6"
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 mb-3 mb-lg-0 ">
                <h2 className="title">Our Vision</h2>
                <p>
                  We have partnered with Mohawk, the largest flooring company in
                  the USA, to offer the widest possible selection at reasonable,
                  discounted prices. Our goal is to make home remodeling
                  affordable for everyone by offering the largest selection of
                  home improvement goods at insanely low prices!
                </p>
              </div>

              <div className="col-lg-6">
                <h2 className="title">Our Mission</h2>
                <p>
                  Whether you’re a homeowner, house flipper, or reseller, you’ll
                  be astonished to see the number of products we have in stock
                  and the record low prices attached to them. We strive to offer
                  amazing products, at amazing prices, to amazing people.
                </p>
              </div>
            </div>
            <div className="row  justify-content-center">
              <div className="col-lg-4 col-sm-6 icon-box-aboutus box">
                <IconBox
                  boxStyle="icon-box-lg text-center"
                  iconClass="icon-puzzle-piece"
                  title="Amazing Value Everyday"
                  text="Products you love at prices that fits your budget."
                />
              </div>
              <div className="col-lg-4 col-sm-6 icon-box-aboutus box">
                <IconBox
                  boxStyle="icon-box-lg text-center"
                  iconClass="icon-life-ring"
                  title="Unbeatable Selection"
                  text="All things home improvements - at one place."
                />
              </div>
              <div className="col-lg-4 col-sm-6 icon-box-aboutus box">
                <IconBox
                  boxStyle="icon-box-lg text-center"
                  iconClass="icon-heart-o"
                  title="Daily updated inventory"
                  text="The largest home improvement goods in town."
                />
              </div>
            </div>
          </div>

          <div className="mb-2"></div>

          <div
            className=" pt-7 pb-5 pt-md-12 pb-md-9"
            style={{
              backgroundColor: `#f9cc1e`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-8 col-md-4">
                  <div className="count-container text-center">
                    <div className="count-wrapper text-dark font-weight-bold">
                      <span
                        className="count"
                        data-from="0"
                        data-to="40"
                        data-speed="3000"
                        data-refresh-interval="50"
                      >
                        0
                      </span>
                      k+
                    </div>
                    <h3 className="count-title text-dark">Happy Customer</h3>
                  </div>
                </div>

                <div className="col-8 col-md-4">
                  <div className="count-container text-center">
                    <div className="count-wrapper text-dark font-weight-bold">
                      <span
                        className="count"
                        data-from="0"
                        data-to="9"
                        data-speed="3000"
                        data-refresh-interval="50"
                      >
                        0
                      </span>
                      +
                    </div>
                    <h3 className="count-title text-dark">Years in Business</h3>
                  </div>
                </div>

                <div className="col-8 col-md-4">
                  <div className="count-container text-center">
                    <div className="count-wrapper text-dark font-weight-bold">
                      <span
                        className="count"
                        data-from="0"
                        data-to="95"
                        data-speed="3000"
                        data-refresh-interval="50"
                      >
                        0
                      </span>
                      %
                    </div>
                    <h3 className="count-title text-dark">Return Clients</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-light-2 pt-6 pb-2 mb-2">
            <div className="container">
              <h2 className="title text-center mb-4">Meet Our Team</h2>

              <div className="row">
                <div className="col-sm-6 col-lg-3">
                  <ProfileTwo
                    image={"/assets/images/team/about-2/anna-nogb.png"}
                    name={"Ana"}
                    title={"CFO"}
                  />
                </div>
                <div className="col-sm-6 col-lg-3">
                  <ProfileTwo
                    image={"/assets/images/team/about-2/maribel-nobgpng.png"}
                    name={"Maribel"}
                    title={"Managing Partner"}
                  />
                </div>
                <div className="col-sm-6 col-lg-3">
                  <ProfileTwo
                    image={"/assets/images/team/about-2/Gonzales-nobg.png"}
                    name={"Gonzales"}
                    title={"Director of Operations"}
                  />
                </div>
                <div className="col-sm-6 col-lg-3">
                  <ProfileTwo
                    image={"/assets/images/team/about-2/normando-nobg.png"}
                    name={"Normando"}
                    title={"Owner "}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="container-fluid"
            style={{ backgroundColor: "#17254a" }}
          >
            <div className="row">
              <div className="col-6 col-sm-5 col-md-7 col-lg-7  offset-lg-1 ">
                <div className="brands-text text-center mx-auto ">
                  <h1 className="title text-white pt-2">
                    We're Commited to Offering Same Brands for Less!
                  </h1>
                </div>
              </div>
              <div className="col-3 d-none d-sm-block d-sm-none d-md-block col-sm-3 col-md-4 col-lg-3   ">
                <img
                  src={`/assets/images/about/about-2/jimenez-truck.png`}
                  alt="truck"

                  style={{ position: "absolute", right: 0}}
                  className="justify-content-end img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default React.memo(AboutTwo);

export const query = graphql`
  query MyQuery3 {
    allMockDataJson {
      nodes {
        team_members {
          img
          name
          title
        }
      }
    }
  }
`;
