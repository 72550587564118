import React from "react";

function ProfileTwo(props) {
  const { image, name, title } = props;

  return (
    <div className="member member-2 text-center">
      <figure className="member-media">
        <img src={image} alt="member" />
      </figure>

      <div className="member-content">
        <h3 className="member-title">
          {name}
          <span>{title}</span>
        </h3>
      </div>
    </div>
  );
}

export default React.memo(ProfileTwo);
